import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
 
@Injectable()
export class LanguageHttpInterceptor implements HttpInterceptor {
 
    constructor(private injector: Injector) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {   
        
        try {
            const translateService = this.injector.get(TranslateService)
            const lang = translateService.currentLang;

            if (lang) {
                request = request.clone({ headers: request.headers.set('Accept-Language', lang) });
            }
        } catch (e){
            // log without translation translation service is not yet available
            //console.error(e);
        }

        return next.handle(request);
    }
 
}