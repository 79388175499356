import { Injectable } from '@angular/core';
import {TokenData} from '../resources/token-data';
import {JwtHelperService} from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable()
export class TokenService {

  constructor(
  ) { }

  getToken(): string | null {
    return localStorage.getItem('access_token');
  }

  setToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  removeToken() {
    localStorage.removeItem('access_token');
  }

  isValid() {
    if (!(this.getTokenData() && !this.isTokenExpired())) {
      return false;
    }
    return ( this.getTokenData() && !this.isTokenExpired() );
  }

  isTokenExpired(): boolean {
    return helper.isTokenExpired(this.getToken());
  }

  expirationDate(): Date | null {

   var token = this.getToken();

   if(token){
     return helper.getTokenExpirationDate(token);
   }
   else{
     return null
   }
  }

  getTokenData(): TokenData | null{
    var token = this.getToken();

    if(token){
      try {
        return helper.decodeToken(token);
      } catch (error) {
        return null;
      }
    }
    else{
      return null
    }

  }

  getUserName(): string | null {
    var token = this.getTokenData()

    if(token) {
      return token.unique_name;
    } else {
      return null;
    }

  }

}

