import { PlanDTO } from "../../../core/models/DTO/planDTO";
import { CpvCategoryDTO } from "../../register/resources/CpvsCategoriesDTO";
import {OrganitzationDTO} from "./organitzationDTO";

export class UserToLogin {
  username: string;
  password: string;
}
export class LoginResult{
  jwt: string;
  refreshToken: string;
  user: UserDTO = new UserDTO();
}
export class UserDTO{
  id: number;
  organitzationId: number;
  mail: string;
  username: string;
  surname: string;
  name: string;
  role: string;
  phone: string;
  isMailConfirmed: boolean;
  isInvitationAcepted: boolean;
  organitzation: OrganitzationDTO;
  language:string;
}
export class RegisterDTO {
  name: string;
  surname: string;
  mail: string;
  password: string;
  language: string;
  phone:string;
  pais:string;
  RaoSocial: string;
  isInvitationAcepted:boolean;
  CIF:string;
  Direccio:string;
  Poblacio:string;
  CP:string;
  isYearPLan: boolean;
  CpvCategoryDtos: CpvCategoryDTO[] = [];
  organitzationPlan: PlanDTO;
  sessionId:string;
  subscriptionStatus: string;
  stripeCustomerId: string;
  comunidadesAutonomas: ComunidadAutonomaDTO[] = [];

}

export class SubscriptionDTO {
  currentPeriodEnd: Date;
  currentPeriodStart: Date;
  priceYear: number;
  priceMonth: number;
  status: string;
}

export class ComunidadAutonomaDTO{
  codCa: string;
  nombre: string;
}
export class ChangePasswordDTO{
  username: string;
  token: string;
  password: string;
}
