import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ApiService} from "../../core/services/api.service";

@Injectable()
export class SharedService {

  constructor(
    private _apiService:ApiService
  ) { }

  public removeTags(tag:string): Observable<any> {
    return this._apiService.get('/Users/removeTag?tag='+ tag);
  }

  public getTags(): Observable<any> {
    return this._apiService.get('/Users/getMyTags');
  }

}
