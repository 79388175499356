import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from "../../../core/services/api.service";
import {UserDTO} from "../../login/resources/user";

@Injectable()
export class OwnerAdministrationService {
  constructor(
  private _apiService:ApiService) {
  }
  public getUsersInOrganitzation(organitzationID:number): Observable<any> {

    var registerURL = '/Users/getUsersInOrganitzation?organitzationId='+organitzationID;
    return this._apiService.get(registerURL);

  }
  public checkMail(mail:string): Observable<any> {
    var registerURL = '/Register/checkMail?mail='+mail;
    return this._apiService.get(registerURL);
  }
  public deletUser(idUser: number): Observable<any> {
    var registerURL = '/Users/delete?userID='+idUser;
    return this._apiService.get(registerURL);
  }
  public sendInvitation(user:UserDTO): Observable<any> {
    var registerURL = '/Users/send-invitation';
    return this._apiService.post(registerURL , user);
  }


}
