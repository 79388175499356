import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NumbersDTO} from "../../../core/models/DTO/numbersDTO";
import {HomeService} from "../../../pages/home/services/home.service";

@Component({
  selector: 'app-card-resum',
  templateUrl: './card-resum.component.html',
  styleUrls: ['./card-resum.component.scss']
})
export class CardResumComponent implements OnInit{
  @Input() numbers: NumbersDTO;
  @Output() emitPage = new EventEmitter<number>();

  constructor( public _homeService: HomeService) {

  }
  ngOnInit(): void {
  }

  public clickTerm(term: number){
    this.emitPage.emit(term)
  }

}
