
import { CommonModule } from '@angular/common';
import { LoginLayoutComponent } from "./login-layout.component";
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    LoginLayoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    LoginLayoutComponent
  ],
})
export class LoginLayoutModule { }
