import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginLayoutModule } from "./core/layouts/login-layout/login-layout.module";
import { DefaultLayoutModule } from "./core/layouts/default-layout/default-layout.module";
import {HeaderModule} from "./core/components/header/header.module";
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, APP_INITIALIZER, ErrorHandler, Injector} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ApiService} from "./core/services/api.service";
import {GlobalService} from "./core/services/global.service";
import {JWT_OPTIONS, JwtModule} from "@auth0/angular-jwt";
import {jwtOptionsFactory} from "./pages/login/resources/jwt-options";
import {TokenService} from "./pages/login/services/token.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AuthenticatedGuard} from "./pages/login/services/authenticated.guard";
import {registerLocaleData} from "@angular/common";
import es from '@angular/common/locales/es';
import ca from '@angular/common/locales/ca';
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from "@abacritt/angularx-social-login";
import {OwnerAdministrationService} from "./pages/owner-administration/services/owner-administration.service";
import {AngularSvgIconModule} from "angular-svg-icon";
import {SharedModule} from "./shared/shared.module";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import {LanguageHttpInterceptor} from "./core/interceptors/LanguageHttpInterceptor";


registerLocaleData(es);
registerLocaleData(ca);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LoginLayoutModule,
        DefaultLayoutModule,
        SocialLoginModule,
        HeaderModule,
        SharedModule,
        NgbModule,
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenService]
            }
        }),
        TranslateModule.forRoot({
            defaultLanguage: 'ca',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        BrowserAnimationsModule
    ],
    providers: [
        AuthenticatedGuard,

        ApiService,
        GlobalService,
        OwnerAdministrationService,
        {provide: LOCALE_ID, useValue: 'ca'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        TokenService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(
                            // replace this with your google client id
                            '743055930244-rtsg1pq04fkvdud1d4bq83prn0m58s27.apps.googleusercontent.com',
                            {
                                oneTapEnabled: false
                            }
                        )
                    }
                ]
            } as SocialAuthServiceConfig,
        }
    ,{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LanguageHttpInterceptor,
        multi: true,
        deps: [Injector]
      },
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
