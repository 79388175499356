declare const env: any;

export const environment = {
  production: false,
  tag: 'pre',
  appVersion: require('../../package.json').version,
  apiURL: 'https://api.pre.alertailicita.codigitalab.com',
  allowedDomains: [
    'api.pre.alertailicita.codigitalab.com'
  ],
  blacklistedRoutes: []
};
