import {Component, OnInit} from '@angular/core';
import {GlobalService} from "../../services/global.service";
import {TokenService} from "../../../pages/login/services/token.service";
import {Router} from "@angular/router";
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {UserDTO} from "../../../pages/login/resources/user";

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit{
  showMenu = false;
  user: UserDTO = new UserDTO();
  constructor(private _globalService: GlobalService,
              private _tokenService: TokenService,
              private _router: Router,
              private authService: SocialAuthService) {
  }
  isActive(route: string): boolean {
    return this._router.isActive(route, {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'});
  }
  ngOnInit(): void {
    this._globalService.menuClicked
      .subscribe((data:boolean) => {
        this.showMenu = data;
      });
    let p = localStorage.getItem('userObject');
    if(p == null){
      this.redirectToLogin();
    }
    let jsonObj = JSON.parse(p!); // string to "any" object first
    this.user = jsonObj as UserDTO;

  }
  public redirectToLogin(){
    this._tokenService.removeToken();
    this.authService.signOut();
    this._router.navigate(['../auth/login']);
  }

}
