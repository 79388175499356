import { Injectable } from '@angular/core';
import {ApiService} from "../../../core/services/api.service";
import {Observable} from "rxjs";

@Injectable()
export class HomeService {

  constructor(
    private _apiService:ApiService
  ) { }

  public getMe(): Observable<any> {
    return this._apiService.get('/Users/getMe');
  }
  public addTag(tag: string): Observable<any> {
    return this._apiService.get('/Users/addTag?tag=' + tag , );
  }
  public getMyStatus(): Observable<any> {
    return this._apiService.getHtml('/Users/getMyStatus');
  }
  public getMySubscriptionDetails(): Observable<any> {
    return this._apiService.get('/Users/getMySubscription');
  }
  public getLicitations(skip: number , take: number , order:number, type: number , searchText: string): Observable<any> {
    return this._apiService.get('/Tenders?skip='+skip+'&take='+take+'&order='+order+'&type='+type +'&textSearch='+searchText);
  }
  public subscribeLicitacions(licitacioId: number , value: boolean): Observable<any> {
    return this._apiService.get('/Tenders/subscribeLicitacio?licitacioId='+licitacioId+'&value='+value);
  }
  public getNumbers(): Observable<any> {
    return this._apiService.get('/Tenders/getTotalsNumbers');
  }

}
