import { NgModule } from '@angular/core';
import {TagContainerComponent} from "./components/tag-container/tag-container.component";
import {SharedService} from "./services/shared.service";
import {HomeService} from "../pages/home/services/home.service";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {CardResumComponent} from "./components/card-resum/card-resum.component";
import { CardPlanPurchaseComponent } from './components/card-plan-purchase/card-plan-purchase.component';
import { FormRegisterComponent } from './components/form-register/form-register.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {SkeletonModule} from "primeng/skeleton";
import {RouterLink} from "@angular/router";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    TagContainerComponent,
    CardResumComponent,
    CardPlanPurchaseComponent,
    FormRegisterComponent
  ],
  exports: [
    TagContainerComponent,
    CardResumComponent,
    CardPlanPurchaseComponent,
    FormRegisterComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    InputTextModule,
    SkeletonModule,
    RouterLink,
    DropdownModule,
    FormsModule
  ],
  providers: [
    SharedService,
    HomeService
  ]
})
export class SharedModule { }
