import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TagsDTO} from "../../../core/models/DTO/tagsDTO";
import {SharedService} from "../../services/shared.service";
import {Observable, Subscription} from "rxjs";

@Component({
  selector: 'app-tag-container',
  templateUrl: './tag-container.component.html',
  styleUrls: ['./tag-container.component.scss']
})
export class TagContainerComponent implements OnInit , OnDestroy{

  public tags: TagsDTO[];
  public isLoading = true;

  private carregaTags: Subscription;
  @Input() eventReloadTags: Observable<void>;
  @Output() reloadParent = new EventEmitter<any>();
  constructor(
    private _sharedService: SharedService
  ) {

  }
  ngOnInit(): void {
    this.reloadTags();
    if(this.eventReloadTags){
      this.carregaTags = this.eventReloadTags.subscribe(() => this.reloadTags());
    }
  }
  reloadTags(){
    this._sharedService.getTags().subscribe(res => {
      this.tags = res;
      this.isLoading = false;
    });
  }

  deleteTags(tag: string){
    this._sharedService.removeTags(tag).subscribe(res => {
      this.reloadParent.emit();
      this.reloadTags();
    });
  }

  ngOnDestroy(): void {
    if( this.carregaTags){
      this.carregaTags.unsubscribe();
    }

  }

}
