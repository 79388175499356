import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {RegisterDTO} from "../../../pages/login/resources/user";

@Component({
  selector: 'app-form-register',
  templateUrl: './form-register.component.html',
  styleUrls: ['./form-register.component.scss']
})
export class FormRegisterComponent implements OnInit{
  @Input()
  public userRegister: RegisterDTO;
  public showErrors = false;
  public mailError = false;
  public countries: any[]
  @Input() public validBeforeNext = new EventEmitter<any>();
  @Input() public disabledMail = true;
  @Output() public emitRegisterUserFromForm = new EventEmitter<RegisterDTO>();
  form: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder,) {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      mail: ['', [Validators.required , Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$")]],
      phone: ['', Validators.required],
      facturacioEmpresa: ['', Validators.required],
      facturacioCIF: ['', Validators.required],
      facturacioPoblacio: ['', Validators.required],
      facturacioPais: ['', Validators.required],
      facturacioDireccio: ['', Validators.required],
      facturacioCP: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.showErrors = false;
    this.userRegister.mail = sessionStorage.getItem("mail") as string;
    if(this.userRegister.mail){
      this.form.controls['mail'].disable()
    }
    this.validBeforeNext.subscribe( res =>{
        this.validForm();
    })

    this.countries = [
      { value: 'DE', text: 'Alemania' },
      { value: 'AT', text: 'Austria' },
      { value: 'BE', text: 'Bélgica' },
      { value: 'BG', text: 'Bulgaria' },
      { value: 'CY', text: 'Chipre' },
      { value: 'HR', text: 'Croacia' },
      { value: 'DK', text: 'Dinamarca' },
      { value: 'SK', text: 'Eslovaquia' },
      { value: 'SI', text: 'Eslovenia' },
      { value: 'ES', text: 'España' },
      { value: 'EE', text: 'Estonia' },
      { value: 'FI', text: 'Finlandia' },
      { value: 'FR', text: 'Francia' },
      { value: 'GR', text: 'Grecia' },
      { value: 'HU', text: 'Hungría' },
      { value: 'IE', text: 'Irlanda' },
      { value: 'IT', text: 'Italia' },
      { value: 'LV', text: 'Letonia' },
      { value: 'LT', text: 'Lituania' },
      { value: 'LU', text: 'Luxemburgo' },
      { value: 'MT', text: 'Malta' },
      { value: 'NL', text: 'Países Bajos' },
      { value: 'PT', text: 'Portugal' },
      { value: 'GB', text: 'Reino Unido' },
      { value: 'CZ', text: 'República Checa' },
      { value: 'RO', text: 'Rumanía' },
      { value: 'SE', text: 'Suecia' }
    ];
  }
  public validForm(){
    if(this.form.get('mail')?.hasError('pattern')){
      this.mailError = true;
    }
    if(this.form.valid ) {
      console.log(this.userRegister);
      this.emitRegisterUserFromForm.emit(this.userRegister);
    }else{
      this.showErrors = true;
    }
  }
}
