import {EventEmitter, Injectable, Output} from '@angular/core';
import {environment} from "../../../environments/environment";


@Injectable()
export class GlobalService {

  public apiURL = environment.apiURL;
  @Output() menuClicked = new EventEmitter<boolean>();
  constructor() {
  }

  menuOpenOrClose(value: boolean) {
    this.menuClicked.emit(value);
  }

}
