import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, tap} from "rxjs";
import {GlobalService} from "./global.service";
import {Router} from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class ApiService extends GlobalService{
  constructor( public _http: HttpClient,
               private router: Router) {

    super();
  }


  public get(url: string): Observable<any> {

    url = this.apiURL + url;
    const p = this._http.get(url).pipe(
      tap({
        next:() =>{

        },
        error: (error: HttpErrorResponse) => {
          this.errorHandler(error);
        }
      }));
    return p;

  }

  public getHtml(url: string) {

    url = this.apiURL + url;
    const p = this._http.get(url, {responseType: 'text'}).pipe(
      tap({
        next:() =>{

        },
        error: (error: HttpErrorResponse) => {
          this.errorHandler(error);
        }
      }));
    return p;

  }
  public put(url: string, data: any) {
    url = this.apiURL + url;
    const p = this._http.put(url, data).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          this.errorHandler(error);
        }
      }));
    return p;
  }

  public post(url: string, data: any) {
    url = this.apiURL + url;
    const p = this._http.post(url, data).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          this.errorHandler(error);
        }
      }));
    return p;
  }
  public postOptions(url: string, data: any , options: any) {
    url = this.apiURL + url;
    const p = this._http.post(url, data , options).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          this.errorHandler(error);
        }
      }));
    return p;
  }

  private async errorHandler(err: HttpErrorResponse) {
    switch (err.status) {
      case 401:
        this.router.navigate(['login']);
        break;
      case 403:
      default:
      /*const t = await this.translate.get(['Oops', 'Errors.GENERIC', 'ACCEPT']).toPromise();
      Swal.fire({
        title: t.Oops,
        text: t['Errors.GENERIC'],
        icon: 'error',
        confirmButtonText: t.ACCEPT,
      });*/
    }
  }


}
