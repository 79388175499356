import {Component, OnInit} from '@angular/core';
import {TokenService} from "../../../pages/login/services/token.service";
import {Router} from "@angular/router";
import {SocialAuthService} from "@abacritt/angularx-social-login";
import {GlobalService} from "../../services/global.service";
import {UserDTO} from "../../../pages/login/resources/user";
import {ProfileService} from "../../../pages/profile/services/profile.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
  public userStatus : UserDTO = new UserDTO();
  public currentLang: string;
 constructor(
   private _tokenService: TokenService,
   private _router: Router,
   private authService: SocialAuthService,
   private _globalService: GlobalService,
   private _profileService: ProfileService,
   private translate: TranslateService,
   private _translate: TranslateService,
 ) {

 }

  ngOnInit(): void {
    var userInLocal = localStorage.getItem('userObject');
    if (userInLocal) {
      this.userStatus = JSON.parse(userInLocal);
    }
    this.currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe((res) => {
      this.currentLang = res.lang;
    });
  }

  public redirectToLogin(){
    this._tokenService.removeToken();
    localStorage.clear();
    this.authService.signOut();
    this._router.navigate(['../auth/login']);
  }

  public getFacturacio(){

    this._profileService.getFacturacio().subscribe({
      next: (res) => {
        window.open(res, '_blank');

      }
    })
  }
  isActive(route: string): boolean {
    return this._router.isActive(route, {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'});
  }
  menuClick() {
    this._globalService.menuOpenOrClose(true);
  }


}
