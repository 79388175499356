import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from "./default-layout.component";
import {HeaderModule} from "../../components/header/header.module";
import {TranslateModule} from "@ngx-translate/core";
import {AngularSvgIconModule} from "angular-svg-icon";

@NgModule({
  declarations: [
    DefaultLayoutComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        TranslateModule,
        AngularSvgIconModule,
    ],
  exports: [
    DefaultLayoutComponent
  ],
})
export class DefaultLayoutModule { }
