import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from "rxjs";
import {PlanDTO} from "../../../core/models/DTO/planDTO";

@Component({
  selector: 'app-card-plan-purchase',
  templateUrl: './card-plan-purchase.component.html',
  styleUrls: ['./card-plan-purchase.component.scss']
})
export class CardPlanPurchaseComponent implements OnInit{

  @Input() plan: PlanDTO;
  @Input() planPrice: number;
  @Input() planTime?: string;
  @Output() planSelected = new EventEmitter<any>();

  ngOnInit(): void {
  }
  emitPlanSelected(plan: PlanDTO){
    this.planSelected.emit(plan);
  }

}
