
import { CommonModule } from '@angular/common';
import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {HeaderComponent} from "./header.component";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatListModule} from "@angular/material/list";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {ProfileService} from "../../../pages/profile/services/profile.service";
import {AngularSvgIconModule} from "angular-svg-icon";
@NgModule({
  declarations: [
    HeaderComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        MatSidenavModule,
        MatListModule,
        NgbPopover,
        TranslateModule,
        AngularSvgIconModule,
    ],
  exports: [
    HeaderComponent
  ],
  providers:[
    ProfileService
  ]
})
export class HeaderModule { }
