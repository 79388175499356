import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
const LNG_KEY = 'LANGUAGE';

export const USER_ALL_LANGUAGES: { key: string, name: string , id:number }[] = [
  {
    key: 'ca',
    name: 'Català',
    id:1
  },
  {
    key: 'es',
    name: 'Español',
    id:2
  }
];

export const DEFAULT_LANGUAGE = 'es';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private availableLanguages = USER_ALL_LANGUAGES;
  constructor(
    private translate: TranslateService,
    private http: HttpClient
  ) {
  }

  public init() {
    const browserLang = this.getBrowserLang();
    this.translate.setDefaultLang(browserLang ?? DEFAULT_LANGUAGE);
    this.checkLocalStorage(browserLang ?? DEFAULT_LANGUAGE);
  }

  public setLanguage(lng: string, persist = false) {

    if (lng == this.translate.currentLang) return;

    this.translate.use(lng);

    if (persist){
      //save localStorage
      localStorage.setItem(LNG_KEY, lng);

      // save lang user
     /* this.auth.isAuthenticated().then((res) => {
        if (res){
          this.userService.User_setLang(lng).subscribe();
        }
      });*/
    }

  }

  private getBrowserLang(){
    const browserLang = this.translate.getBrowserLang();
    if(browserLang != undefined){
      if (USER_ALL_LANGUAGES.map(x => x.key).includes(browserLang)){
        return browserLang;
      }
    }
    return null;
  }
  public getAvailableLanguages(){
    return this.availableLanguages;
  }
  private checkLocalStorage(fallback: string){
    const lang = localStorage.getItem(LNG_KEY);

    if (lang) {
      this.setLanguage(lang);
    }else{
      this.setLanguage(fallback);
    }
  }

  private getActualLang(): string {
      return localStorage.getItem(LNG_KEY) ?? DEFAULT_LANGUAGE;
  }

}
