import { Injectable } from '@angular/core';
import {ApiService} from "../../../core/services/api.service";
import {Observable} from "rxjs";
import {UserDTO} from "../../login/resources/user";

@Injectable()
export class ProfileService {
  constructor(
  private _apiService:ApiService) {
  }
  public getMe(): Observable<any> {
    return this._apiService.get('/Users/getMe');
  }
  public updateUser(user: UserDTO): Observable<any> {
    return this._apiService.post('/Users/updateUser' , user);
  }
  public getFacturacio(): Observable<any> {
    return this._apiService.getHtml('/Users/getFacturacio');
  }
  public getRenew(): Observable<any> {
    return this._apiService.getHtml('/Users/renewSubscription');
  }
  public checkMail(mail:string): Observable<any> {
    var registerURL = '/Register/checkMail?mail='+mail;
    return this._apiService.get(registerURL);
  }
  public deleteAccount(customerID:string): Observable<any> {
    var registerURL = '/Users/cancelSubscription?customerId='+customerID;
    return this._apiService.get(registerURL);
  }

}
