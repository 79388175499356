import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageService} from "./shared/services/language.service";
import {ActivatedRoute} from "@angular/router";
export const AVAILABLE_LANGUAGES = ['es', 'ca'];
export const DEFAULT_LANGUAGE = 'ca';
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit{
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private route: ActivatedRoute
  ) {
    this.languageService.init();
  }
  title = 'web-alerta-licita';

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {

        var language = params['language'];

        if((language == 'es' || language == 'ca') && language != null){
          this.translate.use(language);
        }
    });

  }




}
